<template>
  <div id="graphics-icons" class="graphics-wrap color-overlay-icons">
    <div
      class="item"
      v-for="(componentName, index) in geometryComponents"
      @click="addELToCanvas(index)"
      :ref="'svg' + index"
      :key="index"
    >
      <component
        :class="'svg' + index"
        :is="`geometry${componentName}`"
      ></component>
    </div>
  </div>
</template>

<script>
import { GRAPHICS_MIXIN } from "../graphics.mixins";

export default {
  mixins: [GRAPHICS_MIXIN],
  computed: {
    geometryComponents: function() {
      return Array.from(Array(11).keys());
    },
  },
  created() {
    // https://stackoverflow.com/a/58081641/1247864
    for (let c = 0; c < this.geometryComponents.length; c++) {
      let componentName = this.geometryComponents[c];

      this.$options.components[`geometry${componentName}`] = () =>
        import(
          "../assets/i/geometric-shapes/geometry" + componentName + ".vue"
        );
    }
  },
  methods: {
    addELToCanvas(index) {
      this.$_graphicsMixin_addSVGToCanvas(index);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../index.component.scss";
</style>
